import React, { useEffect } from "react"
import { Container, Row } from "react-bootstrap"
import './OurProducts.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "@StarberryUtils";
import YouTube from 'react-youtube';
import closeIcon from "../../../images/close.png";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const videoRef = React.createRef(null);

const OurProductss = (props) => {
    const [showTour, setVirtualTour] = React.useState(false);
    const [virtualTour, setVirtualTourUrl] = React.useState("");

    const getVideoId = (url) => {
        if(url.split("v=").length > 1) {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return video_id
        } else if(url.split("embed/").length > 0) {
            return url.split("embed/")[1]
        } else {
            return url.split("youtu.be/")[1]
        }
    }

    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            setVirtualTourUrl("");
            setVirtualTour(false);
        }
    }

    useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);

    const playVideo = () => {
        var elem = videoRef.current;
        const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        if(fullScreenAvailable) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            elem.classList.add("fullscreen-video")
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'local-living-video',
            'formName': "Local Living Video",
            'formLabel': window.location.href
        });
    }

    const closeVideo = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setVirtualTourUrl("");
        setVirtualTour(false);
    }
    console.log("Area", props.data)
    return (
        <section className="our-products">
            <ScrollAnimation animateIn='fadeInUp' animateOnce>
                <Container className="area-guide-landing">
                    <Row>
                        {props.data.map((area, i) => {
                            let processedImages = JSON.stringify({});
                            if (area?.imagetransforms?.images_Transforms) {
                                processedImages = area.imagetransforms.images_Transforms;
                            }
                            return (
                                <div className="product-wrapper area-guide-list col-md-4" key={i}><Link to={area.URL}>
                                    {area.Tile_Image &&
                                        <ImageTransform
                                            imagename="area-guides.Tile_Image.tile"
                                            attr={{ alt: area.Tile_Image.alternativeText, class:'' }}
                                            imagesources={area.Tile_Image.url}
                                            renderer="srcSet"
                                            imagetransformresult={processedImages}
                                            id={area._id}
                                        />
                                    }
                                    <div className="product-info">
                                        <h2>
                                            {area.Title}
                                    </h2>
                                    </div></Link>
                                    <div className="gallery-btn">
                                    {area.Embed_Video_URL &&
                                        <div className="product-btn">
                                            <strong className="video-btn" >
                                                <i className="icon-video" onClick={async e => {
                                                    await setVirtualTourUrl(area.Embed_Video_URL);
                                                    await setVirtualTour(true);
                                                    playVideo();
                                                }}/>
                                            </strong>
                                        </div>
                                    }
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </Row>
                </Container>
            </ScrollAnimation>
            <div ref={videoRef}>
                {(virtualTour && showTour) && 
                    <div>
                        <YouTube
                            className="video-inline"
                            videoId={getVideoId(virtualTour)}
                            opts={{
                                height: "100%",
                                width: "100%",
                                playerVars: {
                                    autoplay: 1,
                                    disablekb: 1
                                }
                            }}
                            onEnd={closeVideo}
                            onError={(err) => {
                                console.log(err)
                                closeVideo()
                            }}
                        />
                        <div className="btn btn-light close-icon" onClick={closeVideo}>
                            <img src={closeIcon} alt="close-icon"/>
                        </div>
                    </div>
                }
            </div>
        </section>

    )
};
export default OurProductss
