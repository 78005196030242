import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import moment from "moment/src/moment";
import axios from "axios";
import * as qs from "query-string";
import { WINDSOR_POST_CODE } from "../common/site/constants";

const to_email_id = process.env.MAIL_TO;

function MySimpleForm(props) {
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [formvalues, setFormvalues] = useState("");
    const [token, setToken] = useState("");
    const myRef = React.createRef();
    const recaptchaRef = React.createRef();
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        axios.get('https://extreme-ip-lookup.com/json/').then(response => {
        //console.log("countryCode", response.data.countryCode);
            setCountryCode(response.data.countryCode)
        }).catch(err =>
        console.log(err)
        );
    },[]);
    const fields = ([
        {
            element: "config",
            formname: "Consultation Form",
            form_type: "book-a-consultaion",
            error_text: "Highlighted fields are required | invalid",
            success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
            email_temp_user: "contact_user",
            email_temp_admin: "contact_admin",
            email_server_func: "book-a-consultaion",
            event_tracking: "book-a-consultaion",
            page_url: "/contact"
        },
        {
            grpmd: "12",
            label: "Name",
            placeholder: "Name",
            name: "name",
            type: "text",
            element: "input",
            required: true,
            patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: "Telephone",
            placeholder: "Phone",
            name: "telephone",
            type: "text",
            element: "input",
            required: true,
            patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: "Email",
            placeholder: "Email",
            name: "email",
            type: "email",
            element: "input",
            required: true,
            patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
            labelClass: "content_b-18"

        },
        {
            grpmd: "6",
            label: "Select Date",
            placeholder: "Select Date",
            name: "preferred-date",
            type: "date",
            element: "input",
            required: true,
            labelClass: "content_b-18"
        },
        {
            grpmd: "6",
            label: "Select Time",
            placeholder: "Select Time",
            name: "preferred-time",
            type: "time",
            element: "input",
            required: true,
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: "Post Code",
            placeholder: "Post Code",
            name: "postcode",
            type: "text",
            element: "input",
            required: true,
            labelClass: "content_b-18"
        },
        {
            grpmd: "3",
            text: 'Interested in',
            element: "html",
            class: "mb-md-0 mb-3 content_r-m interested_in"
        },
        {
            grpmd: "3",
            label: "Sales",
            placeholder: "Sales",
            name: "postcode",
            type: "checkbox",
            required: false,
            element: "checkbox",
            labelClass: "content_b-18"
        },
        {
            grpmd: "3",
            label: "Lettings",
            placeholder: "Lettings",
            name: "postcode",
            type: "checkbox",
            required: false,
            element: "checkbox",
            labelClass: "content_b-18"
        },
        {
            grpmd: "3",
            label: "Property Management",
            placeholder: "Property Management",
            name: "postcode",
            type: "checkbox",
            required: false,
            element: "checkbox",
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            name: "SUBMIT",
            type:"submit",
            element: "button",
            value: "Book Viewing",
            class: "btn btn-primary col-12",
            labelClass: "content_b-18"
        },
        {
            element: "captcha",
            class: "py-2",
            captchaRef: recaptchaRef
        },
    ]);

    const handlechange = event => {
        // remove initial empty spaces
        event.target.value = event.target.value.trimStart()

    }

    useEffect(() => {
        if (token !== '') {
            const processFromData = async () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify(formvalues));

                postFormData(formData).then(apiRes => {
                    // lets send mail
                    const axiosOptions_email = {
                        url: '/.netlify/functions/server/'+fields[0].email_server_func,
                        method: "post",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        data: qs.stringify(formvalues),
                    }
                    axios(axiosOptions_email).then(response => {
                        console.log('mail sent!')
                    })
                    .catch(err =>
                        console.log(err)
                    );
                });

                formvalues['g-recaptcha-response'] = token;

                const interested_in_datas = {
                    "Sales": formvalues.sales,
                    "Lettings" : formvalues.lettings,
                    "Property Management": formvalues.property_management
                }
        
                let interested_in = Object.entries(interested_in_datas).map(([key, val]) => {
                    if(val == "yes") {
                        return key
                    }
                }).filter(c => c);

                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': interested_in.join(", "),
                    'formId': 'form-'+fields[0].event_tracking,
                    'formName': fields[0].formname,
                    'formLabel': fields[0].formname
                });

                setShowerror(false);
                setThankyou(true);
                setTimeout(() => {
                    setThankyou(false)
                }, 3000);
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

            }
            processFromData();
        }
    }, [token]);

    const handleonVerify = token => {
        setToken(token);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setShowerror(true);
            setValidated(true);
            setThankyou(false);
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
            const formsdata = (event.target);
            const json = {};
            Object.keys(formsdata).map(key => (
                json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            ))
            json['email_temp_user'] = fields[0].email_temp_user;
            json['email_temp_admin'] = fields[0].email_temp_admin;
            json['formname'] = fields[0].formname;
            json['g-recaptcha-response'] = token;

            if(props.email && props.email !='') {
                json["to_email_id"] = props.email;
            }

            // if(WINDSOR_POST_CODE.includes(json.postcode)) {
            //     json['to_email_id'] = 'windsor@bovingdons.com';
            // }

            setFormvalues(json);

            recaptchaRef.current.props.grecaptcha.execute();
            
            setValidated(false);
            // reset form
            const form = event.target
            form.reset();
        
        }
    };

    return (
        <div className="form stbform">
            <div ref={myRef} />
            {showerror && <div className="alert alert-danger text-center mt-4">
                <p className="mb-0">{fields[0].error_text}</p>
            </div>}

            {showthankyou && <div className="alert alert-success text-center mt-4">
                <p className="mb-0">{fields[0].success_text}</p>
            </div>}

            
            <Form name={fields[0].formname} action="/thank-you/" method="post" id="consulation-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <input type="hidden" name="form_name" value={fields[0].formname} />
                <input type="hidden" name="form_type" value={fields[0].form_type} />
                <input type="hidden" name="to_email_id" value={to_email_id} />
                <input type="hidden" name="bot-field" />
                <Form.Row>
                    {fields.map((field, index) => {
                        if ( "input" === field.element ) {
                            return (
                                <InputField
                                    key={index}
                                    name={field.name}
                                    grpmd={field.grpmd}
                                    ref={field.ref}
                                    type={(field.type === "date" || field.type === "time") ? "text" : field.type}
                                    fieldClass={field.class}
                                    placeholder={field.placeholder}
                                    labelClass={field.labelClass}
                                    required={field.required}
                                    key={`${field.element}~${index}`}
                                    pattern={field.patternchk}
                                    handlechange={handlechange}
                                    min={field.type == "date" ? moment().format("YYYY-MM-DD") : ""}
                                    onFocus={(e) => {
                                        if(field.type === "date" || field.type === "time") {
                                            e.target.type = field.type
                                        }
                                    }}
                                />
                            );
                        }
                        if ("select" === field.element) {
                            return (
                                <SelectField
                                    key={index}
                                    name={field.name}
                                    grpmd={field.grpmd}
                                    label={field.label}
                                    ref={field.ref}
                                    required={field.required}
                                    fieldClass={field.class}
                                    placeholder={field.placeholder}
                                    values={field.values}
                                    key={`${field.element}~${index}`}
                                    handlechange={handlechange}
                                    componentprops={props}
                                />
                            );
                        }
                        if ("textarea" === field.element) {
                            return (
                                <TextAreaField
                                    key={index}
                                    name={field.name}
                                    grpmd={field.grpmd}
                                    ref={field.ref}
                                    rows={field.rows}
                                    fieldClass={field.class}
                                    labelClass={field.labelClass}
                                    placeholder={field.placeholder}
                                    required={field.required}
                                    key={`${field.element}~${index}`}
                                    handlechange={handlechange}
                                />
                            );
                        }
                        if ("checkbox" === field.element) {
                            return (
                                <CheckboxField
                                    key={index}
                                    name={field.name}
                                    ref={field.ref}
                                    value={field.value}
                                    fieldClass={field.class}
                                    placeholder={field.placeholder}
                                    required={field.required}
                                    key={`${field.name}~${index}`}
                                    handlechange={handlechange}
                                />
                            );
                        }
                        if ("html" === field.element) {
                            return (
                                <HtmlBox
                                    key={index}
                                    text={field.text}
                                    fieldClass={field.class}
                                    key={`${field.element}~${index}`}
                                />
                            );
                        }
                        if ("captcha" === field.element) {
                            return (
                                <ReCaptchaBox
                                    key={index}
                                    fieldClass={field.class}
                                    captRef={field.captchaRef}
                                    key={`${field.element}~${index}`}
                                    handleonVerify={handleonVerify}
                                />
                            );
                        }
                        if ("button" === field.element) {
                        return (
                                <>
                                <div className="col-md-6 col-sm-12 col-xs-12 bk_btn_mb">
                                </div>
                                <ButtonField
                                    key={index}
                                    name={field.name}
                                    fieldClass={field.class}
                                    type={field.type}
                                    value={field.value}
                                    key={`${field.element}~${index}`}
                                    grpmd={field.grpmd}
                                />
                                </>
                            );
                        }
                    })
                }
                </Form.Row>
            </Form>
        </div>
    );
}


const ConsulationForm = (props) => (
    <MySimpleForm {...props}/>
)

export default ConsulationForm;