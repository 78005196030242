import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import OurProducts from "../../Static/OurProducts/OurProducts"
 

function AreaGuides(props) {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                areaGuides(where: {Publish: true}, sort: "Sort") {
                  URL
                  Title
                  Tile_Image {
                    url
                    alternativeText
                  }
                  local_living {
                    id
                  }
                  Embed_Video_URL
                  id
                  _id
                }
            }
        }
    `);

   console.log('local', data.glstrapi.areaGuides)

    const result = data.glstrapi.areaGuides.filter(living => living.local_living.id ==  props.id);

    return (
        <OurProducts data={result}/>
    )
}

export default AreaGuides;