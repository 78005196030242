import React from "react";
import HTMLReactParser from 'html-react-parser';
import { GetURL } from "../common/site/functions";
import { Modal } from "react-bootstrap";
import ConsulationForm from "../../components/forms/consultation-form";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const OfficeBanner = (props) => {
    const [showForm, setShowForm] = React.useState(false);
    let url = ''
    if (props.link) {
        url = GetURL(props.link._id)
    }
    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Banner_Image_Transforms;
    }
    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="banner contact-page-banner office-page-banner local-living-landing-page"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <div className="bg-img">
                            {props.image &&
                                <ImageTransform
                                    imagesources={props.image}
                                    renderer="srcSet" imagename="articles.Banner_Image.details"
                                    attr={{ alt: props.image_alt, class: '' }}
                                    imagetransformresult={processedImages}
                                    id={props.id}
                                />
                            }
                            <div className="content bg-content">
                                <h5>Living In {props.pagename}</h5>
                                {
                                    props.content &&
                                    HTMLReactParser(props.content.replace(/\n/g, '<br />'))
                                }

                                <p>If you are looking for more information about buying, selling, becoming a landlord, or letting a property then feel free to get in contact with us at Bovingdon's, where we are always happy to have a chat about your next move.</p>
                                {/* {props.label &&
                                    <a onClick={() => setShowForm(true)} className="btn btn-primary">
                                        {props.label}
                                    </a>
                                } */}
                            </div>
                        </div>
                        <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                            <Modal.Header closeButton>
                                <Modal.Title className="px-0">Book a Consultation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ConsulationForm />
                            </Modal.Body>
                        </Modal>
                    </motion.section>
                )
            }}
        </InView>
    )
}

export default OfficeBanner