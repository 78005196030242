import * as React from "react"
import { graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import '../components/Static/AboutPage/About.scss';
import AreaGuides from "../components/collections/AreaGuides/AreaGuides";
import LivingBanner from "../components/LocalLiving/LivingBanner";
import SEO from "../components/seo";


const LocalLivingTemplate = (props) => {
    console.log('local', props.pageContext)
    return (
        <div className="wrapper wrapper-static">
            {/* <SEO
                title={`${GQLPage.Title} Area Guide`}
                description={`Read here and get to Know more about ${GQLPage.Title} living and the property, Contact Bovingdons team today.`}
            />  */}
            <Header />
            <LivingBanner
                video={``}
                content={props.pageContext.living.Description}
                label={null}
                link={null}
                pagename={props.pageContext.living.Name}
            />

            <div className="area-guide-details">
                <AreaGuides id={props.pageContext.id}  />
            </div>
            <Footer search="Popular_Search_Static" />
        </div>
    )
}

export default LocalLivingTemplate
